.pullee {
    width: 100%;
    appearance: none;
    touch-action: none;
}

.pullee:active::-webkit-slider-thumb {
    @apply shadow-md;
    appearance: none;
    transform: scale(1.1);
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
}

.pullee:active::-moz-range-thumb {
    border: 0;
    transform: scale(1.1);
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
}

.pullee:active::-ms-thumb {
    transform: scale(1.1);
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
}

.pullee:focus {
    outline: none;
}

.pullee + p {
    pointer-events: none;
    color: white;
    text-align: center;
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.5s;
}

.pullee + p.small {
    top: 1rem;
    height: 3.5rem;
    width: 100%;
    padding: 0 3.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 12px;
    font-weight: 700;
}

.pullee::-webkit-slider-thumb {
    @apply shadow-sm;
    position: relative;
    appearance: none;
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: url(../../assets/icons/right-arrows.svg) #fff center no-repeat;
    transform-origin: 50% 50%;
    transform: scale(1);
    transition: transform ease-out 100ms;
    cursor: -webkit-grab;
    cursor: -moz-grab;
}

.pullee::-moz-range-thumb {
    @apply shadow-sm;
    position: relative;
    border: 0;
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: url(../../assets/icons/right-arrows.svg) #fff center no-repeat;
    transform-origin: 50% 50%;
    transform: scale(1);
    transition: transform ease-out 100ms;
    cursor: -webkit-grab;
    cursor: -moz-grab;
}

.pullee::-ms-thumb {
    @apply shadow-sm;
    position: relative;
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background: url(../../assets/icons/right-arrows.svg) #fff center no-repeat;
    transform-origin: 50% 50%;
    transform: scale(1);
    transition: transform ease-out 100ms;
    cursor: -webkit-grab;
    cursor: -moz-grab;
}

.pullee::-webkit-slider-runnable-track {
    height: 3rem;
    padding: 0.25rem;
    box-sizing: content-box;
    border-radius: 3rem;
    background-color: #12A679;
}

.pullee::-moz-range-track {
    height: 3rem;
    padding: 0.25rem;
    box-sizing: content-box;
    border-radius: 1rem;
    background-color: #12A679;
}

.pullee::-moz-focus-outer {
    border: 0;
}

.pullee::-ms-track {
    border: 0;
    height: 3rem;
    padding: 0.25rem;
    box-sizing: content-box;
    border-radius: 1rem;
    background-color: #12A679;
    color: transparent;
}

.pullee::-ms-fill-lower,
.pullee::-ms-fill-upper {
    background-color: transparent;
}

.pullee::-ms-tooltip {
    display: none;
}