.swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: white;
    height: 12px;
    width: 12px;
}

.swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    background-color: white;
    opacity: 0.5;
    transition: all 0.3s;
}

.swiper-slide:not(.swiper-slide-active) > * {
    opacity: 0.5;
}

.info-page .swiper-slide {
    height: 200px;
}

.info-page .swiper-slide > * {
    max-height: 200px;
}

.info-page .swiper-slide:not(.swiper-slide-active) > * {
    max-height: 160px;
}

.info-page .swiper-slide:not(.swiper-slide-active) {
    align-self: center;
}

.offset-page .swiper-slide {
    padding: 0 6px;
}