@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    a {
        @apply text-accent
    }
    html {
        @apply text-primary
    }
}

#root {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.react-select__menu-portal {
    z-index: 10 !important;
}

@screen md {
    .wrapper-size {
        min-height: 55%;
    }
}

.invalid-field .stripe-field {
    @apply border-red-500
}

.stripe-field.invalid-field {
    @apply border-red-500
}

.stripe-field {
    @apply border-transparent
}

input:checked+div {
    @apply border-accent;
}

input:checked+div svg {
    @apply block;
}

.cert-tonne-display {
    position: absolute;
    top: 23%;
    left: 8%;
    right: 8%;
}