.react-select__control {
    padding: 1.25rem;
    border-style: none !important;
  }
  
  .react-select__value-container {
    padding: 0 !important;
  }
  
  .react-select__placeholder {
    color: #9ca3af !important;
  }
  
  .react-select__dropdown-indicator {
    padding: 0 !important;
  }
  
  .react-select__indicator-separator {
    display: none;
  }
  
  .react-select__value-container .css-b8ldur-Input {
    padding: 0 !important;
    margin: 0 !important;
  }
  
  .react-select__menu {
    position: relative;
    box-sizing: border-box;
  }